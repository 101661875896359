import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import Layout from 'components/Order/Layout';
import Auth from 'components/Auth';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import IconLogo from 'images/logo-1.svg';
import IconNoData from 'images/order-no-data.svg';
import CcAuth from 'components/CcAuth';
import { PATH_CART } from 'utils/constants/paths.js';
import TitleName from 'components/UI/Header/TitleName';

const mixinOrderList = css`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${md}) {
    gap: 16px 0;
  }
`;

const mixinOrderItem = css`
  flex-basis: calc((100% - 12px) / 2);
  border-radius: 8px;
  min-height: 112px;

  @media (max-width: ${md}) {
    flex-basis: 100%;
    min-height: 79px;
  }
`;

const StyledLayout = styled(Layout)``;

const Btn = styled.div`
  flex: 0 0 96px;
  height: 32px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: ${md}) {
    font-size: 14px;
    flex: 0 0 88px;
    height: 29px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 24px 14px 0;
  }

  ${(props) =>
    props.noData &&
    css`
      background-image: url(${IconNoData});
      background-size: cover;
      width: 263px;
      height: 300px;
      margin: auto;
    `}

  .skeleton-order-list {
    ${mixinOrderList};

    .skeleton-order {
      ${mixinOrderItem};
    }
  }
`;

const OrderListBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const OrderListTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    font-size: 18px;
    margin-bottom: 24px;
  }
`;

const OrderListWrapper = styled.div`
  ${mixinOrderList};
`;

const OrderItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${md}) {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 16px;
  }
`;

const OrderItem = styled.div`
  ${mixinOrderItem};
  background-color: #ffffff;
  padding: 18px 14px;
  display: flex;
  gap: 0 12px;
  cursor: pointer;

  @media (max-width: ${md}) {
    padding: 0;
    align-items: flex-start;
  }

  &:last-child {
    ${OrderItemContent} {
      @media (max-width: ${md}) {
        height: 100%;
        border-bottom: none;
      }
    }
  }
`;

const OrderItemImgWrapper = styled.div`
  margin: auto 0;
  position: relative;

  &::before {
    content: '${(props) => props.count}';
    position: absolute;
    top: 5px;
    right: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f9e05f;
    font-size: 12px;
    font-weight: 400;
    color: #3b3516;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const OrderItemImg = styled.img`
  width: 64px;
  height: 64px;
`;

const OrderItemContentBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 0 5px;

  @media (max-width: ${md}) {
    justify-content: space-between;
  }
`;

const OrderItemTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 16px;
  }
`;

const OrderItemInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #868686;

  @media (max-width: ${md}) {
    font-size: 14px;
  }
`;

const CheckProgressBtn = styled(Btn)`
  background-color: #eafeff;
  border: 1px solid #5fd2da;
  color: #214a4c;
  align-self: flex-end;
`;

const ReOrderBtn = styled(Btn)`
  background-color: #f2f2f2;
  color: #3b3516;
  align-self: flex-end;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    background-image: url(${IconNoData});
    width: 263px;
    height: 300px;
  }
`;

const OrderType = {
  FINISHED: 30, //完成
  CANCEL: 40, // 取消
  DISPATCH: 20, //配送中
  TEN: 10, // 狀態10
};

const OrderList = (props) => {
  const pageTitle = '訂單查詢';
  const api = useAPI();
  const loading = useLoading();

  const [dispatchOrder, setDispatchOrder] = useState();
  const [finishedOrder, setFinishedOrder] = useState();
  const [showEmpty, setShowEmpty] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);

  const getOrderList = () => {
    api
      .getOrders()
      .then((res) => {
        if (res.length > 0) {
          const dispatchArray = [];
          const finishedArray = [];
          for (const item of res) {
            if (item.order_status_id === OrderType.DISPATCH) {
              dispatchArray.push(item);
            } else if (
              [OrderType.CANCEL, OrderType.FINISHED, OrderType.TEN].includes(
                item.order_status_id
              )
            ) {
              finishedArray.push(item);
            }
          }
          setDispatchOrder(dispatchArray);
          setFinishedOrder(finishedArray);
          return;
        }
        setShowEmpty(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const reOrder = (transNo) => {
    loading.show();
    api
      .reOrder(transNo)
      .then((res) => {
        if (!res) return;
        navigate(PATH_CART, { replace: true });
      })
      .catch((error) => {})
      .finally(() => loading.dismiss());
  };

  const goOrderDetailPage = (transNo) => {
    navigate(`/order/${transNo}?source=list`);
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    getOrderList();
  }, [ccAuthIsReady]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <StyledLayout {...props} pageTitle={pageTitle}>
          <Auth />
          <Container>
            {!showEmpty && (
              <>
                <OrderListBlock>
                  <OrderListTitle>最新訂單</OrderListTitle>
                  {dispatchOrder !== undefined ? (
                    <OrderListWrapper>
                      {dispatchOrder.map((item) => {
                        return (
                          <OrderItem
                            onClick={() => {
                              goOrderDetailPage(item.sfcc_transaction_no);
                            }}
                          >
                            <OrderItemImgWrapper count={item.order_item_qty}>
                              <OrderItemImg src={IconLogo} />
                            </OrderItemImgWrapper>
                            <OrderItemContent>
                              <OrderItemTitle>{`${item.code} ${item.store_name}`}</OrderItemTitle>
                              <OrderItemContentBlock>
                                <OrderItemInfo>
                                  <div>
                                    品項{item.order_item_qty}・${item.amount}
                                  </div>
                                  <div>
                                    {item.order_date}・{item.ship_status}
                                  </div>
                                </OrderItemInfo>
                                <CheckProgressBtn>查看進度</CheckProgressBtn>
                              </OrderItemContentBlock>
                            </OrderItemContent>
                          </OrderItem>
                        );
                      })}
                    </OrderListWrapper>
                  ) : (
                    <Skeleton
                      containerClassName="skeleton-order-list"
                      className="skeleton-order"
                      count={6}
                      inline
                    />
                  )}
                </OrderListBlock>
                <OrderListBlock>
                  <OrderListTitle>過往訂單</OrderListTitle>
                  {finishedOrder !== undefined ? (
                    <OrderListWrapper>
                      {finishedOrder.map((item) => {
                        return (
                          <OrderItem
                            onClick={() => {
                              goOrderDetailPage(item.sfcc_transaction_no);
                            }}
                          >
                            <OrderItemImgWrapper count={item.order_item_qty}>
                              <OrderItemImg src={IconLogo} />
                            </OrderItemImgWrapper>
                            <OrderItemContent>
                              <OrderItemTitle>{`${item.code} ${item.store_name}`}</OrderItemTitle>
                              <OrderItemContentBlock>
                                <OrderItemInfo>
                                  <div>
                                    品項{item.order_item_qty}・${item.amount}
                                  </div>
                                  <div>
                                    {item.order_date}・{item.ship_status}
                                  </div>
                                </OrderItemInfo>
                                <ReOrderBtn
                                  onClick={(event) => {
                                    event.stopPropagation(); //防止觸發外圍事件
                                    reOrder(item.sfcc_transaction_no);
                                  }}
                                >
                                  重新下單
                                </ReOrderBtn>
                              </OrderItemContentBlock>
                            </OrderItemContent>
                          </OrderItem>
                        );
                      })}
                    </OrderListWrapper>
                  ) : (
                    <Skeleton
                      containerClassName="skeleton-order-list"
                      className="skeleton-order"
                      count={6}
                      inline
                    />
                  )}
                </OrderListBlock>
              </>
            )}
            {showEmpty && <Empty />}
          </Container>
        </StyledLayout>
      )}
    </>
  );
};

export default OrderList;
